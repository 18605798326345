import { Component, ViewChild, OnDestroy, AfterViewInit, Renderer2, ElementRef, AfterViewChecked } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { CurvaCambioService } from '../../services/CurvaCambioService';
import { ToastService } from '../../services/toastService';
import { PrecificacaoService } from '../../services/precificacaoService';
import { ProdutoService } from '../../services/produtoService';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from '../../services/sharedService';
import moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import { MatMenuTrigger } from '@angular/material/menu';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TipoTransacaoEnum } from '../../enums/TipoTransacaoEnum';
import { MoedaEnum } from '../../enums/MoedaEnum';
import { LoginService } from '../../services/loginService';
import { map, Observable, of } from 'rxjs';
import { Globals } from '../../globals/globals';
import { Calculo } from '../../model/calculo';
import { TipoNegocioEnum } from '../../enums/TipoNegocioEnum';

@Component({
  selector: 'app-book-ofertas',
  templateUrl: './bookOfertas.html',
  styleUrls: ['./bookOfertas.css'],
})
export class BookOfertas implements OnDestroy, AfterViewInit, AfterViewChecked {
  ss;
  menuVisivel: Boolean = true;
  @ViewChild(MatMenuTrigger, { static: false }) public matMenu: MatMenuTrigger;
  selectedOption: string = 'contains';
  filtrarPorTexto: string


  constructor(
    private toastService: ToastService,
    private router: Router,
    private curvaCambioService: CurvaCambioService,
    private precificacaoService: PrecificacaoService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private produtoService: ProdutoService,
    ss: SharedService,
    private el: ElementRef,
    private fb: FormBuilder,
    private loginService: LoginService, private globals: Globals) {
    this.ss = ss;
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.limparIntervals();
      }
    });

  }

  rotaAtual() {
    return this.router.url;
  }


  adicionarStiloHeader() {
    const theadElement = this.el.nativeElement.querySelector('thead');
    if (theadElement != null) {
      theadElement.style.position = 'sticky';
      theadElement.style.top = '0';
      theadElement.style.background = 'white';
    }
  }
  ngAfterViewInit(): void {
    //this.adicionarStiloHeader();
  }

  ngAfterViewChecked() {
    this.adicionarStiloHeader();
  }

  public mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  showCard: boolean = false;
  carregando: boolean = false;
  resposta: any = {};
  dto: any = {
    dataInicio: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).toISOString().split('T')[0],
    dataFim: new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate()).toISOString().split('T')[0],
    cambioMerc: {},
    cambioRodo: {},
    cambioFerro: {},
    cambioPremio: {},

    premio: {},
    rodo: {},
    pracaFerro: {},
    ferro: {},
    pracaRodo: {},
    cBotNumber: 0
  };
  cBoat: any = [];
  premios: any = [];
  filtro: any = {
    tamanhoPagina: 100,
    numeroPagina: 1,
    tipoFrete: "",
    idProduto: 1
  };
  filtroPrec: any = {
    tamanhoPagina: 100,
    numeroPagina: 1,
    idProduto: 1
  };
  filtroFerrovia: any = {
    tamanhoPagina: 100,
    pagina: 1,
    tipoFrete: "2"
  };
  filtroRodovia: any = {
    tamanhoPagina: 100,
    pagina: 1,
    tipoFrete: "1"

  };
  listaPraca: any[] = [];
  listaRodo: any[] = [];
  listaFerro: any[] = [];
  dolarFuturo: any = {};
  listaPorto: any[] = [];
  listaComponente: any[] = [];
  listaPrecificacao: any[] = [];
  listaGraos: any[] = [];
  dataRodo: string;
  dataFerro: string;
  dataMerc: string;
  dataMin: string;
  dataPremio: string;
  dataMax: string;
  setIntervalId: any;
  precificacaoSelecionada: any;
  closeResult: string;
  listaComponentesRetencao: any[] = [];
  prec: any;
  intervals = [];
  mostrarTitulo: boolean = false;
  ofertasFiltradas: any[] = [];

  searchText;
  public columnsFilters = {};
  formulario = 1;
  public dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  concluirNegocioForm: FormGroup;
  readonly fatorSoja: number = 0.367454;
  readonly fatorMilho: number = 0.393685;
  readonly TipoTransaoEnum = TipoTransacaoEnum;
  readonly MoedaEnum = MoedaEnum;
  readonly TipoNegocioEnum: TipoNegocioEnum;
  cbotInformativo: any = {};
  options: any = [];
  filteredOptions$: Observable<any[]>;
  keyword = 'nome';
  usuariosFiltrados: any = [];
  carregandoUsuarios = false;
  primeiraConsulta = true;
  ngOnInit() {
    this.obterListaProdutos();
    this.obterCbot();

    this.setIntervalId = setInterval(() => {
      this.obterCbot();
    }, 7000);
    this.intervals.push(this.setIntervalId);
    var novaData = new Date();
    this.menuVisivel = localStorage["expandir"] == "true";
    this.route.paramMap.subscribe(params => {
      if (params.has("expandir")) {
        this.expandirPainel(params.get("expandir") == "true");
      }
    });
    this.dataMin = `${novaData.getFullYear().toString()}-${("0" + (novaData.getMonth() + 1)).slice(-2).toString()}-${("0" + novaData.getDate()).slice(-2).toString()}`;
    this.dataMax = `${(novaData.getFullYear() + 5).toString()}-${("0" + (novaData.getMonth() + 1)).slice(-2).toString()}-${("0" + novaData.getDate()).slice(-2).toString()}`;

    this.concluirNegocioForm = this.fb.group({
      precificacao: [],
      contraparteAPesquisar: [],
      nome: [''],
      contraparte: [''],

      volume: ['', Validators.required],
      tipoTransacao: [null, Validators.required],
      moedaTransacao: [null, Validators.required],
      valor: ['', Validators.required],
      bidPremio: [],
      bidDolar: [],
      bidReal: [],
      ofertaPremio: [],
      ofertaDolar: [],
      ofertaReal: []
    });
    this.filteredOptions$ = of(this.options);
    this.globals.customVariable.subscribe({
      next: newValue => {
        this.resposta = newValue;
        if (this.primeiraConsulta && !this.isEmptyObject(this.resposta)) {
          this.obterListaPrecificacao(1);
          this.primeiraConsulta = false;
        }
      }
    });
  }
  isEmptyObject(obj) {
    return (obj && (Object.keys(obj).length === 0));
  }


  obterListaPrecificacao(pagina) {
    this.carregando = true;
    this.listaPrecificacao = [];
    this.dataSource.data = [];
    this.ofertasFiltradas = [];
    this.filtroPrec.pagina = pagina;
    this.filtroPrec.dolarCompra = this.resposta.cotacaoCompra;
    this.filtroPrec.dolarVenda = this.resposta.cotacaoVenda;

    this.limparIntervals();
    this.precificacaoService.obterListaPrecificacaoPorUsuario(this.filtroPrec).subscribe(response => {
      this.carregando = false;
      if (response.codigo === 200) {
        this.listaPrecificacao = response.result.retorno;
        this.ofertasFiltradas = this.listaPrecificacao;
        var ctx = this;
        this.listaPrecificacao.forEach(a => {
          a.nomeComMoeda = a.nome + `${a.moedaAtualizar}`
          a.verMais = false;
          a.valoresOferta = this.valoresOfertaVenda(a);
          a.valoresBid = this.valoresBidCompra(a);

          this.obterDolarFuturo(a.dataMercString, a, 1);
          this.obterDolarFuturo(a.dataRodoString, a, 2);
          this.obterDolarFuturo(a.dataFerroString, a, 3);



          a.cbotValor = this.atualizarValorCbot(a);
          a.bid = this.valorDolar(a, "Compra");
          a.bidRs = this.valorRs(a, "Compra");

          a.oferta = this.valorDolar(a, "Venda");
          a.ofertaRs = this.valorRs(a, "Venda");

          a.totalComponenteBid = this.calcularRetencao(a, "Compra");
          a.totalComponenteOferta = this.calcularRetencao(a, "Venda");

          a.ofertaPremio = ctx.premioCbotPor(a, "Venda");
          a.bidPremio = ctx.premioCbotPor(a, "Compra");

          if (this.precificacaoSelecionada?.precificacaoId == a.precificacaoId) {
            this.selecionarPrecificacao(a);
          }

          var i = setInterval(function () {
            a.bid = self.valorDolar(a, "Compra");
            a.bidRs = self.valorRs(a, "Compra");

            a.oferta = self.valorDolar(a, "Venda");
            a.ofertaRs = self.valorRs(a, "Venda");

            a.ofertaCbot = self.ofertaCbot(a);
            a.ofertaPremio = self.premioCbotPor(a, "Venda");
            a.bidPremio = self.premioCbotPor(a, "Compra");

            a.cbotValor = self.atualizarValorCbot(a);
            self.obterDolarFuturo(a.dataMercString, a, 1);
            self.obterDolarFuturo(a.dataRodoString, a, 2);
            self.obterDolarFuturo(a.dataFerroString, a, 3);

            a.valoresOferta = ctx.valoresOfertaVenda(a);
            a.valoresBid = ctx.valoresBidCompra(a);
          }, 2000);
          this.intervals.push(i);
        });
        this.dataSource.data = this.listaPrecificacao;
        var self = this;


        this.filtroPrec.total = response.result.qtdTotal
      }
      else
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
    })
  }

  private filtrar(value: string) {
    this.carregandoUsuarios = true;
    const filterValue = value.toLowerCase();
    this.loginService.pesquisarUsuarioPorNome(filterValue).subscribe(res => {
      this.carregandoUsuarios = false;
      this.usuariosFiltrados = res
    });
  }


  limparConcluirNegocioForm() {
    this.concluirNegocioForm.reset();
  }

  selectEvent(contraparte) {
    this.form.contraparteAPesquisar.setValue(null);
    this.form.contraparte.setValue(contraparte);
  }

  onChangeSearch(val: string) {
    this.form.contraparteAPesquisar.setValue(val);
    this.filtrar(val);
  }

  removerContraparte() {
    this.form.contraparteAPesquisar.setValue(null);
    this.form.contraparte.setValue(null);
  }

  get form() { return this.concluirNegocioForm.controls; }

  concluir(modal, precificacao) {
    this.open(modal, precificacao);
    this.cbotInformativo = {};
    this.cbotPossuiDadoPrecificacao(this.precificacaoSelecionada);
    this.limparConcluirNegocioForm();
    this.form.nome.setValue(this.precificacaoSelecionada.nome);
    this.form.precificacao.setValue(this.precificacaoSelecionada);
    this.form.ofertaPremio.setValue(`${this.precificacaoSelecionada.premioStr}`);

    this.form.ofertaPremio.setValue(this.precificacaoSelecionada.ofertaPremio);
    this.form.ofertaDolar.setValue(this.precificacaoSelecionada.oferta);
    this.form.ofertaReal.setValue(this.precificacaoSelecionada.ofertaRs);

    this.form.bidPremio.setValue(this.precificacaoSelecionada.bidPremio);
    this.form.bidDolar.setValue(this.precificacaoSelecionada.bid);
    this.form.bidReal.setValue(this.precificacaoSelecionada.bidRs);
  }

  atualizarListageCbotEBookOfertas() {
    this.obterCbot();
    this.obterListaPrecificacao(1);
  }

  cbotPossuiDadoPrecificacao(precificacao): Boolean {
    var cbotValor = this.cBoat.quotes.find(cbot => {
      return cbot.quoteCode == precificacao.cbot;
    });
    this.cbotInformativo.carregou = true;

    if (cbotValor == null) {
      this.cbotInformativo.mensagem = "Cbot não trouxe dados"
      return false;
    }

    if (cbotValor.last == '-') {
      this.cbotInformativo.mensagem = `Não foi possível carregar os dados.CBOT retornou valor: ${cbotValor.last}`
      return false;
    }

    return true
  }

  onFirstSubmit() {

    if (this.carregandoUsuarios) {
      this.toastService.show(`Aguarde consultando existentes por ${this.form.contraparteAPesquisar.value}`, { classname: 'bg-warning', delay: 5000 })
      return;
    }


    if ((this.form.contraparte.value == null || this.form.contraparte.value == "") && this.form.contraparteAPesquisar.value == null) {
      this.toastService.show("Informe a contraparte", { classname: 'bg-warning', delay: 5000 })
      return;
    }

    if (this.form.volume.value == null || this.form.volume.value == "") {
      this.toastService.show("Informe o volume", { classname: 'bg-warning', delay: 5000 })
      return;
    }
    
    if ((this.form.tipoTransacao.value == null || this.form.tipoTransacao.value == "") || this.form.moedaTransacao.value == null ) {
      this.toastService.show("Selecione uma valor", { classname: 'bg-warning', delay: 5000 })
      return;
    }
  }

  onBackSubmit() {
    this.limparOperacaoSelecionada();
  }

  get valorEstaMaiorQueOPremioSelecionado(): Boolean {
    var precificacao = this.precificacaoSelecionada;

    var oferta = precificacao.oferta;
    var bid = precificacao.bid;

    if (this.form.tipoTransacao.value == this.TipoTransaoEnum.BID)
      return bid > oferta;

    if (this.form.tipoTransacao.value == this.TipoTransaoEnum.OFFER)
      return oferta < bid;

    return false;

  }

  get premioSelecionadoConclusao(): number {
    var precificacao = this.form.precificacao.value;
    var premio = precificacao.ofertaPremio;
    if (this.form.tipoTransacao.value == this.TipoTransaoEnum.OFFER)
      premio = precificacao.bidPremio;

    return premio
  }

  premioSelecionado(precificacao) {
    var premio = precificacao.bidPremio;

    if (precificacao.tipoNegocio == TipoNegocioEnum.VENDA)
      premio = precificacao.ofertaPremio;

    return premio
  }

  get produtoSelecionado(): number {
    var precificacao = this.precificacaoSelecionada;
    if (this.form.tipoTransacao.value == this.TipoTransaoEnum.OFFER)
      return precificacao.bid;

    return precificacao.oferta;
  }

  get produtoSelecionadoRs(): number {
    var precificacao = this.precificacaoSelecionada;
    if (this.form.tipoTransacao.value == this.TipoTransaoEnum.OFFER)
      return precificacao.bidRs;

    return precificacao.ofertaRs;
  }

  premioPorTipoNegocioNaConclusao(precificacao, tipoNegocio) {

    if (tipoNegocio == "Venda")
      return `${precificacao.premioStr} @ ${this.formatarPrecoDolar(precificacao.ofertaPremio)}`;

    return `${precificacao.premioStr} @ ${this.formatarPrecoDolar(precificacao.bidPremio)}`;
  }

  operacaoSelecionada(tipo: TipoTransacaoEnum, moeda: MoedaEnum): Boolean {
    return this.form.tipoTransacao.value == tipo && this.form.moedaTransacao.value == moeda
  }

  operacaoOfferOuBidSelecionada(tipo: TipoTransacaoEnum, moeda: MoedaEnum): string {
    var estiloBotao = 'btn-outline-success';
    var precificacao = this.precificacaoSelecionada;
    var bid = precificacao.bid;
    var selecionado = this.operacaoSelecionada(tipo, moeda);
    var oferta = precificacao.oferta;
    if (this.form.tipoTransacao.value == this.TipoTransaoEnum.BID) {
      //Verde
      if (bid > oferta) {
        if (selecionado) {
          estiloBotao = 'btn-success'
        }
      }
      else {
        estiloBotao = 'btn-outline-danger';
        if (selecionado) {
          estiloBotao = 'btn-danger'
        }
      }
    }

    //Verde
    if (bid > oferta) {
      if (selecionado) {
        estiloBotao = 'btn-success'
      }
    }
    else {
      estiloBotao = 'btn-outline-danger';
      if (selecionado) {
        estiloBotao = 'btn-danger'
      }
    }
    return estiloBotao;
  }


  get tipoTransacao() {
    if (this.form.tipoTransacao.value == this.TipoTransaoEnum.BID) { return "BID" };
    return "OFFER";
  }

  get valorConclusaoFormatado() {
    if (this.form.moedaTransacao.value == this.MoedaEnum.DOLAR) {
      return this.formatarPrecoDolar(this.form.valor.value);
    }
    return this.formatarPrecoBrasil(this.form.valor.value);
  }

  get premioConlusaoTransacao() {
    var precificacao = this.precificacaoSelecionada;
    var premio = `${precificacao.premioStr} @ `;
    var valor = this.formatarPrecoDolar(precificacao.ofertaPremio);
    if (this.form.tipoTransacao.value == this.TipoTransaoEnum.OFFER) {
      valor = this.formatarPrecoDolar(precificacao.bidPremio);
    }
    return premio + valor
  }

  limparOperacaoSelecionada() {
    this.form.moedaTransacao.setValue(null);
    this.form.valor.setValue(null);
  }

  selecionarOperacao(tipo: TipoTransacaoEnum, moeda: MoedaEnum, valor: number) {
    this.form.tipoTransacao.setValue(tipo);
    this.form.moedaTransacao.setValue(moeda);
    this.form.valor.setValue(valor);
  }

  ngOnDestroy(): void {
    this.limparIntervals();
  }

  limparIntervals() {
    this.intervals.forEach(clearInterval);
    this.intervals = [];
  }


  searchKey(data: string) {
    this.searchText = data;
    this.search();
  }

  search() {
    let filtro = this.searchText.trim().toLowerCase();
    this.ofertasFiltradas = this.searchText === "" ? this.listaPrecificacao : this.listaPrecificacao.filter((element) => {
      return element.nome.toLowerCase().includes(filtro)
        || element.porto.toLowerCase().includes(filtro)
        || element.cbot.toLowerCase().includes(filtro)
        || element.valor.toString().includes(filtro)
        || element.valorRs.toString().includes(filtro)
        || element.premioValor.toString().includes(filtro)
        || element.cbotValor.toString().includes(filtro)
        || element.valor.toString().includes(filtro)
        || element.premioStr.toLowerCase().includes(filtro);
    });
  }



  obterListaProdutos() {
    this.produtoService.obterListaProduto().subscribe(response => {
      if (response) {
        this.listaGraos = response;
      }
    })
  }


  obterFreteFerro() {
    this.filtroFerrovia.pracaId = this.dto.pracaFerro.pracaId;
  }

  obterFreteRodo() {
    this.filtroRodovia.pracaId = this.dto.pracaRodo.pracaId;
  }

  obterDolarFuturo(dataFim, precificacao, hedge) {

    var dtoDolar = {
      dolarCompra: this.resposta.cotacaoCompra,
      dolarVenda: this.resposta.cotacaoVenda,
      dataInicio: new Date(),
      dataFim: moment(dataFim, "DD/MM/YYYY").format("yyyy-MM-DD")
    }

    this.curvaCambioService.obterDolarFuturo(dtoDolar).subscribe(response => {
      if (response.codigo === 200) {
        var result = response.result;
        if (hedge === 1) {
          precificacao.hedgeMercadoCompra = result.dolarCompra;
          precificacao.hedgeMercadoVenda = result.dolarVenda;
        }
        if (hedge === 2) {
          precificacao.hedgeRodoCompra = result.dolarCompra;
          precificacao.hedgeRodoVenda = result.dolarVenda;
        }
        if (hedge === 3) {
          this.dto.cambioFerro = response.result;
          precificacao.hedgeFerroCompra = result.dolarCompra;
          precificacao.hedgeFerroVenda = result.dolarVenda;
        }

        this.ofertaDolarParaReal(precificacao, "Compra");
        this.ofertaDolarParaReal(precificacao, "Venda");

        this.ofertaRealParaDolar(precificacao, "Compra");
        this.ofertaRealParaDolar(precificacao, "Venda");

        this.calcularRetencao(precificacao, "Compra");
        this.calcularRetencao(precificacao, "Venda");
      }
      else {
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
      }
    })
  }


  tipoNegocio(precificacao) {
    return precificacao.tipoNegocioString == "Compra" ? "BID" : "Oferta";
  }

  premioOposto(precificacao) {
    return this.mostrarNomeOposto(precificacao) == "Oferta" ? precificacao.ofertaPremio : precificacao.bidPremio;
  }

  mostrarNomeOposto(precificacao) {
    var tipo = this.tipoNegocio(precificacao);
    if (tipo == "BID")
      return "Oferta";

    if (tipo == "Oferta")
      return "BID";

    return tipo;
  }

  mostrarPrecoDolarOposto(precificacao) {
    var tipoNegocio = this.mostrarNomeOposto(precificacao);
    if (tipoNegocio == "BID") return precificacao.bid;
    return precificacao.oferta;
  }

  mostrarPrecoRealOposto(precificacao) {
    var tipoNegocio = this.mostrarNomeOposto(precificacao);
    if (tipoNegocio == "BID") return precificacao.bidRs;
    return precificacao.ofertaRs;
  }

  mostrarNegocioSelecionadoSiglaValor(tipoNegocio, precificacao) {

    if (precificacao.tipoNegocioString == tipoNegocio) {
      if (precificacao.idMoedaAtualizar == MoedaEnum.DOLAR)
        return "USD";

      return "RS"
    }

    return ""
  }

  mostrarNegocioSelecionado(tipoNegocioString: TipoNegocioEnum, precificacao): boolean {
    return precificacao.tipoNegocio == tipoNegocioString;
  }

  valoresOfertaVenda(precificacao) {
    return this.porTipoNegocio(precificacao, "Venda")
  }

  valoresBidCompra(precificacao) {
    return this.porTipoNegocio(precificacao, "Compra")
  }

  porTipoNegocio(precificacao, tipoNegocio) {
    return `${this.formatarPrecoDolar(this.valorDolar(precificacao, tipoNegocio))} | ${this.formatarPrecoBrasil(this.valorRs(precificacao, tipoNegocio))} ${this.mostrarNegocioSelecionadoSiglaValor(tipoNegocio, precificacao)}`;
  }

  valorDolar(precificacao, tipoNegocio) {
    if (precificacao.tipoNegocioString == tipoNegocio) {
      if (precificacao.idMoedaAtualizar == MoedaEnum.DOLAR)
        return precificacao.valor;

      var hedgeMercado = this.hedgeMercado(tipoNegocio, precificacao);
      return precificacao.valorRs / hedgeMercado;
    }

    return this.calcularPrecificao(precificacao, tipoNegocio);
  }

  hedgeMercado(tipoNegocio, precificacao) {
    return tipoNegocio == "Compra" ? precificacao.hedgeMercadoCompra : precificacao.hedgeMercadoVenda;
  }

  hedgeRodo(tipoNegocio, precificacao) {
    return tipoNegocio == "Compra" ? precificacao.hedgeRodoCompra : precificacao.hedgeRodoVenda;
  }

  hedgeFerro(tipoNegocio, precificacao) {
    return tipoNegocio == "Compra" ? precificacao.hedgeFerroCompra : precificacao.hedgeFerroVenda;
  }

  valorRs(precificacao, tipoNegocio) {
    if (precificacao.tipoNegocioString == tipoNegocio) {
      if (precificacao.idMoedaAtualizar == MoedaEnum.REAL)
        return precificacao.valorRs;

      var hedgeMercado = this.hedgeMercado(tipoNegocio, precificacao);
      return precificacao.valor * hedgeMercado;
    }

    return this.calcularPrecificaoRs(precificacao, tipoNegocio);
  }

  formatarPrecoBrasil(price: number) {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(price);
  }

  valorDolarPorTipoNegocio(precificacao) {
    return precificacao.tipoNegocioString == "Compra" ? precificacao.bid : precificacao.oferta;
  }

  valorRealPorTipoNegocio(precificacao) {
    return precificacao.tipoNegocioString == "Compra" ? precificacao.bidRs : precificacao.ofertaRs;
  }

  formatarPrecoDolar(price: number) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(price);
  }

  atualizarValorCbot(precificacao) {
    var cbotValor = this.cBoat.quotes?.find(cbot => {
      return cbot.quoteCode == precificacao.cbot;
    });

    if (cbotValor != null && cbotValor.last != '-') {
      var valorCbot = parseFloat(cbotValor.last.replace(/'/g, '.'));
      if (precificacao.cbotValor != valorCbot && valorCbot > 0) {
        precificacao.cbotValor = valorCbot;
        this.precificacaoService.atualizarValorCbot({ precificacaoId: precificacao.precificacaoId, cbotValor: precificacao.cbotValor });
      }
    }

    return precificacao.cbotValor;
  }

  calcularPrecificao(precificacao, tipoNegocio) {

    var cbotValor = this.cBoat.quotes?.find(cbot => {
      return cbot.quoteCode == precificacao.cbot;
    });

    if (cbotValor == null || cbotValor.last == '-') {
      return precificacao.tipoNegocioString == tipoNegocio ? precificacao.oferta : precificacao.bid;
    }

    var hedgefreteRodo = this.hedgeRodo(tipoNegocio, precificacao);
    var hedgefreteFerro = this.hedgeFerro(tipoNegocio, precificacao);


    var converte = parseFloat(cbotValor.last.replace(/'/g, '.'));
    return (((converte + precificacao.premioValor) *
      precificacao.produtoFator) -
      ((precificacao.valorFreteRodo / hedgefreteRodo) +
        (precificacao.valorFreteFerro / hedgefreteFerro) +
        this.calcularRetencao(precificacao, tipoNegocio))) * 0.06;


  }

  calcularPrecificaoRs(precificacao, tipoNegocio) {
    var cbotValor = this.cBoat.quotes?.find(cbot => {
      return cbot.quoteCode == precificacao.cbot;
    });

    if (cbotValor == null || cbotValor.last == '-') {
      return precificacao.tipoNegocioString == tipoNegocio ? precificacao.ofertaRs : precificacao.bidRs;
    }

    var hedgeMercado = this.hedgeMercado(tipoNegocio, precificacao);
    var hedgefreteRodo = this.hedgeRodo(tipoNegocio, precificacao);
    var hedgefreteFerro = this.hedgeFerro(tipoNegocio, precificacao);

    var converte = parseFloat(cbotValor.last.replace(/'/g, '.'));
    var valorEmDolar = ((((converte + precificacao.premioValor) *
      precificacao.produtoFator) -
      ((precificacao.valorFreteRodo / hedgefreteRodo) +
        (precificacao.valorFreteFerro / hedgefreteFerro) +
        this.calcularRetencao(precificacao, tipoNegocio))) * 0.06);

    return valorEmDolar * hedgeMercado;

  }

  ofertaCbot(precificacao) {

    var hedgefreteRodo = this.hedgeRodo(precificacao.tipoNegocioString, precificacao);
    var hedgefreteFerro = this.hedgeFerro(precificacao.tipoNegocioString, precificacao);

    var baseCompra = (precificacao.valorFreteRodo / hedgefreteRodo) + (precificacao.valorFreteFerro / hedgefreteFerro) + this.totalComponente(precificacao, precificacao.tipoNegocioString);
    return (((precificacao.valor / 0.06) + baseCompra) / precificacao.produtoFator) - precificacao.premioValor;
  }

  premioCbotPor(precificacao, tipoNegocio) {

    if (precificacao.tipoNegocioString == tipoNegocio)
      return this.ofertaPremioCbot(precificacao, tipoNegocio);

    return precificacao.premioValor;
  }

  ofertaPremioCbot(precificacao, tipoNegocio) {
    var cbotValor = this.cBoat.quotes?.find(cbot => {
      return cbot.quoteCode == precificacao.cbot;
    });

    if (cbotValor == null || cbotValor.last == '-') {
      cbotValor = precificacao.cbotValor;
    }
    else {
      cbotValor = parseFloat(cbotValor.last.replace(/'/g, '.'));
    }

    var hedgefreteRodo = this.hedgeRodo(tipoNegocio, precificacao);
    var hedgefreteFerro = this.hedgeFerro(tipoNegocio, precificacao);

    var baseCompra = (precificacao.valorFreteRodo / hedgefreteRodo) + (precificacao.valorFreteFerro / hedgefreteFerro) + this.totalComponente(precificacao, tipoNegocio);
    var converte = cbotValor;

    return (((precificacao.valor / 0.06) + baseCompra) / precificacao.produtoFator) - converte;

  }

  selecionarPrecificacao(precificacao) {
    this.precificacaoSelecionada = precificacao;
  }

  concluirPrecificao() {
    this.status(this.precificacaoSelecionada, 2, false);
  }

  totalComponente(precificacao, tipoNegocio) {
    return this.calcularRetencao(precificacao, tipoNegocio);
  }

  calcularRetencao(precificacao, tipoNegocio) {
    var custos = 0;
    var ctx = this;
    precificacao.componentes.filter((x: any) => x.calculo != null).forEach(function (componente) {
      custos += ctx.calcularRetencaoPorComponente(precificacao, tipoNegocio, componente);
    });

    return ctx.somatorioComponentesSemRetencoes(precificacao, tipoNegocio) + custos;
  }

  totalComponenteSemCalculoDolar(precificacao) {
    var total = 0;
    precificacao.componentes.filter((x: any) => x.calculo == null && x.moedaId == MoedaEnum.DOLAR).forEach((componente, index) => {
      total += componente.custo;
    });
    return parseFloat(Math.round(total).toFixed(4));
  }

  totalComponenteSemCalculoEmRealConvertidoEmDolar(precificacao, tipoNegocio) {

    var total = 0;
    var hedgefreteFerro = this.hedgeFerro(tipoNegocio, precificacao);
    precificacao.componentes.filter((x: any) => x.calculo == null && x.moedaId == MoedaEnum.REAL).forEach((componente, index) => {
      var dolar = componente.moedaId == MoedaEnum.REAL ? (componente.custo / hedgefreteFerro) : componente.valor
      total += parseFloat(Math.round(dolar).toFixed(4));
    });
    return parseFloat(Math.round(total).toFixed(4));
  }

  somatorioComponentesSemRetencoes(precificacao, tipoNegocio) {
    return this.totalComponenteSemCalculoDolar(precificacao) + this.totalComponenteSemCalculoEmRealConvertidoEmDolar(precificacao, tipoNegocio);
  }

  calcularRetencaoPorComponente(precificacao, tipoNegocio, componente) {

    var calculo = componente.calculo
    var custo = componente.custo;

    //FOB ORIGEM
    if (calculo == 1) {
      return +(this.fobOrigem(precificacao, tipoNegocio) * custo / 100).toFixed(4);
    }
    //TRANSBORDO
    else if (calculo == 2) {
      var transbordo = this.transbordo(precificacao, tipoNegocio) * custo / 100;
      return +transbordo.toFixed(4);
    }
    //POOL
    else if (calculo == 3) {
      var custoRetencaoPool = (this.pool(precificacao, tipoNegocio) * custo) / 100;
      return +custoRetencaoPool.toFixed(4);
    }

    return 0;
  }

  fobOrigem(precificacao, tipoNegocio) {

    var hedgefreteRodo = this.hedgeRodo(tipoNegocio, precificacao);
    var hedgefreteFerro = this.hedgeFerro(tipoNegocio, precificacao);

    return Math.abs(Calculo.calcularFobOrigem(precificacao.cbotValor,
      precificacao.premioValor,
      precificacao.valorFreteFerro,
      hedgefreteFerro,
      precificacao.valorFreteRodo,
      hedgefreteRodo,
      precificacao.produtoFator) - this.somatorioComponentesSemRetencoes(precificacao, tipoNegocio));
  }

  transbordo(precificacao, tipoNegocio) {
    var hedgefreteFerro = this.hedgeFerro(tipoNegocio, precificacao);

    return Math.abs(Calculo.calcularTransbordo(precificacao.cbotValor,
      precificacao.premioValor,
      precificacao.valorFreteFerro,
      hedgefreteFerro,
      precificacao.produtoFator) - this.somatorioComponentesSemRetencoes(precificacao, tipoNegocio));
  }

  pool(precificacao, tipoNegocio) {

    return Math.abs(Calculo.calcularPool(precificacao.cbotValor,
      precificacao.premioValor,
      precificacao.produtoFator) - this.somatorioComponentesSemRetencoes(precificacao, tipoNegocio));
  }

  status(precificacao, status, solicitarConfirmacao) {

    var cbotSelecionado = this.cBoat.quotes.find(cbot => cbot.quoteCode == precificacao.cbot);
    var cbotExiste = cbotSelecionado != null && cbotSelecionado.length > 0;

    var dto: any = {
      status: status,
      cbotCode: precificacao.cbot,
      valorCbot: cbotExiste ? parseFloat(this.cBoat.quotes.find(cbot => cbot.quoteCode == precificacao.cbot).last.replace(/'/g, '.')) : precificacao.cbotValor,
      precificacaoId: precificacao.precificacaoId,

      hedgeRodoCompra: precificacao.hedgeRodoCompra,
      hedgeRodoVenda: precificacao.hedgeRodoVenda,

      hedgeFerroCompra: precificacao.hedgeFerroCompra,
      hedgeFerroVenda: precificacao.hedgeFerroVenda,

      hedgeMercadoCompra: precificacao.hedgeMercadoCompra,
      hedgeMercadoVenda: precificacao.hedgeMercadoVenda,

      valor: precificacao.valor,
      valorRs: precificacao.valorRs
    }

    if (status == 2) {
      var negocioForm = this.concluirNegocioForm.getRawValue();
      dto.volumeNegociado = negocioForm.volume;
      dto.tipoTransacao = negocioForm.tipoTransacao;
      dto.moeda = negocioForm.moedaTransacao;
      dto.nomeContraparte = negocioForm.contraparteAPesquisar;
      dto.contraparteId = negocioForm.contraparte?.usuarioId;
      dto.premioValor = this.premioSelecionadoConclusao;
      dto.produtoValor = this.produtoSelecionado;
      dto.produtoValorRs = this.produtoSelecionadoRs;
    }

    if (!solicitarConfirmacao) {
      this.mudarStatus(dto);
      return;
    }


    var acao = confirm("Deseja realizar esta ação?");
    if (acao) {
      this.mudarStatus(dto);
    }
  }

  mudarStatus(dto) {
    this.precificacaoService.mudarStatus(dto).subscribe(response => {
      if (response.codigo == 200) {
        this.toastService.show('Status alterado com sucesso!', { classname: 'bg-success text-light', delay: 5000 })
        this.obterListaPrecificacao(this.filtroPrec.pagina);
      }
      else
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
    })
  }

  ofertaRealParaDolar(precificacao, tipoNegocio) {
    var hedgeMercado = this.hedgeMercado(tipoNegocio, precificacao);

    if (precificacao.idMoedaAtualizar != MoedaEnum.DOLAR && precificacao.tipoNegocioString == tipoNegocio) {
      precificacao.valor = +(precificacao.valorRs / hedgeMercado).toFixed(2);
    }
  }

  ofertaDolarParaReal(precificacao, tipoNegocio) {
    var hedgeMercado = this.hedgeMercado(tipoNegocio, precificacao);

    if (precificacao.idMoedaAtualizar != MoedaEnum.REAL && precificacao.tipoNegocioString == tipoNegocio) {
      precificacao.valorRs = +(precificacao.valor * hedgeMercado).toFixed(2);
    }
  }

  open(content, precificacao) {
    this.selecionarPrecificacao(precificacao);
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: false }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  countWarning: number = 0;
  private obterCbot() {
    this.curvaCambioService.obterCboat(this.filtroPrec.idProduto).subscribe(response => {
      if (response.mensagem == "CME") {
        if (response.url == null) {
          this.toastService.show("Serviço indiponível no momento");
          return;
        }
        var timer = new Date().getTime();
        var url = response.url;
        var concat = url + timer;
        this.curvaCambioService.obterCboatCME(concat).subscribe(cbot => this.cBoat = cbot);
      }
      else
        this.cBoat = JSON.parse(response.result);

      if (this.cBoat.quotes != null) {
        let count = this.cBoat.quotes.filter(a => a.last.includes('-')).length;
        if (count > 0 && this.countWarning == 0) {
          this.toastService.show("Existem " + count + " CBOT sem último valor", { classname: 'bg-warning', delay: 5000 })
          this.countWarning++;
        }
      }
    });
  }

  validarCorVerde(precificacao, real) {
    var nome = this.mostrarNomeOposto(precificacao);

    var bid = precificacao.bid;
    var oferta = precificacao.oferta;

    if (real) {
      bid = precificacao.bidRs;
      oferta = precificacao.ofertaRs;
    }

    if (nome == "BID")
      return bid > oferta;

    if (nome == "Oferta")
      return oferta < bid;

    return false;
  }

  validarCorVermelho(precificacao, real) {
    var nome = this.mostrarNomeOposto(precificacao);
    var bid = precificacao.bid;
    var oferta = precificacao.oferta;

    if (real) {
      bid = precificacao.bidRs;
      oferta = precificacao.ofertaRs;
    }

    if (nome == "BID") {
      return bid < oferta;
    }

    if (nome == "Oferta")
      return oferta > bid;

    return false;
  }

  marcarComoSelecionado(tipoNegocio: number, tipoMoeda: MoedaEnum, precificacao): string {
    var indicadorSelecionado = "badge badge-pill";
    var estaSelecionado = precificacao.idMoedaAtualizar == tipoMoeda && precificacao.tipoNegocio == tipoNegocio;

    var bid = precificacao.bid;
    var oferta = precificacao.oferta;

    if (precificacao.idMoedaAtualizar == MoedaEnum.REAL) {
      bid = precificacao.bidRs;
      oferta = precificacao.ofertaRs;
    }

    if (tipoNegocio == TipoNegocioEnum.COMPRA && estaSelecionado) {
      if (bid > oferta)
        return indicadorSelecionado + " badge-success";
      if (bid < oferta)
        return indicadorSelecionado + " badge-danger";
    }

    if (tipoNegocio == TipoNegocioEnum.VENDA && estaSelecionado) {
      if (oferta > bid)
        return indicadorSelecionado + " badge-danger";
      if (oferta < bid)
        return indicadorSelecionado + " badge-success";
    }

    if (!estaSelecionado)
      return "";

    return indicadorSelecionado + " badge-secondary";
  }



  expandir() {
    this.expandirPainel(!this.menuVisivel);
  }

  expandirPainel(expandir) {

    localStorage["expandir"] = expandir;
    if (expandir) {
      this.menuVisivel = true;
      this.ss.show();
    }
    else {
      this.menuVisivel = false;
      this.ss.hide();
    }
  }

  voltarParaBookOfertas() {
    localStorage["expandir"] = false;
    this.expandirPainel(false);
    this.router.navigate(['/bookOfertas'])
  }

  abrirofertasExpandidoEmNovaTab() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/bookOfertas/true`])
    );

    window.open(url, '_blank');
  }

  toggleWithGreeting(popover, precificacao) {
    if (popover.isOpen()) {
      popover.close();
    } else {
      this.prec = precificacao;

      popover.open(this.prec);
    }
  }

  private filter() {

    this.dataSource.filterPredicate = (data: any, filter: string) => {

      let find = true;

      for (var columnName in this.columnsFilters) {

        let currentData = ("" + data[columnName]).normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase();

        if (!this.columnsFilters[columnName]) {
          return;
        }

        let searchValue = this.columnsFilters[columnName]["contains"];

        if (!!searchValue && currentData.indexOf("" + searchValue.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase()) < 0) {

          find = false;
          return;
        }

        searchValue = this.columnsFilters[columnName]["equals"];

        if (!!searchValue && currentData != ("" + searchValue).normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase()) {
          find = false;
          return;
        }

        searchValue = this.columnsFilters[columnName]["greaterThan"];

        if (!!searchValue && currentData <= ("" + searchValue).normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase()) {
          find = false;
          return;
        }

        searchValue = this.columnsFilters[columnName]["lessThan"];

        if (!!searchValue && currentData >= ("" + searchValue).normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase()) {
          find = false;
          return;
        }

        searchValue = this.columnsFilters[columnName]["startWith"];

        if (!!searchValue && !currentData.startsWith("" + searchValue.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase())) {
          find = false;
          return;
        }

        searchValue = this.columnsFilters[columnName]["endWith"];

        if (!!searchValue && !currentData.endsWith("" + searchValue.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase())) {
          find = false;
          return;
        }
      }
      return find;

    };

    this.dataSource.filter = null;
    this.dataSource.filter = 'activate';

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  applyFilter(columnName: string, operationType: string, searchValue: string) {

    this.columnsFilters[columnName] = {};
    this.columnsFilters[columnName][operationType] = searchValue;
    this.filter();
    this.closeMenu();
  }

  clearFilter(columnName: string) {
    if (this.columnsFilters[columnName]) {
      delete this.columnsFilters[columnName];
      this.filter();
    }
    this.closeMenu();
  }

  limparCamposFiltro() {
    this.selectedOption = "contains";
    this.filtrarPorTexto = null;
  }

  onMenuOpened(columnName: string) {
    this.limparCamposFiltro();
    if (this.columnsFilters[columnName]) {
      let chave = Object.keys(this.columnsFilters[columnName])[0];
      let valor = Object.values(this.columnsFilters[columnName])[0];

      this.selectedOption = "" + chave;
      this.filtrarPorTexto = "" + valor;
    }
  }


  jaFiltrado(columnName: string) {
    return this.columnsFilters[columnName];
  }

  closeMenu() {
    this.matMenu.closeMenu();
  }

}
