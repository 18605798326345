<main>
  <nb-card>
    <nb-card-body>
      <ul class="nav nav-tabs" style="padding-top: 20px;">
        <li class="nav-item" [routerLink]="['/precificacao']">

          <span class="nav-link {{ !milho ? 'active' : ''}}"> <i class="mdi mdi-grain mdi-24px"></i> {{'Menus.Precificacao-Soja' | translate}}</span>
        </li>
        <li class="nav-item" [routerLink]="['/precificacao/milho']">

          <span class="nav-link {{milho ? 'active' : ''}}"> <i class="mdi mdi-corn mdi-24px"></i> {{'Menus.Precificacao-Milho' | translate}}</span>
        </li>
      </ul>
      <div class="container">
        <div class="text-center">
          <h3 *ngIf="milho">{{'Menus.Precificacao' | translate}} - {{'Menus.Precificacao-Milho' | translate}}</h3>
          <h3 *ngIf="!milho">{{'Menus.Precificacao' | translate}} - {{'Menus.Precificacao-Soja' | translate}}</h3>
        </div>
        <form>
          <!-- #region Medida -->

          <div class="form-row bg-dark text-white p-3">
            <div class="col">{{'componente.unidadeMedida' | translate}}</div>
            <div class="col d-none d-sm-block"></div>
            <div></div>
            <div class="col text-center">U$</div>
          </div>
          <div class="form-row pl-3 pt-1">
            <div class="col">{{'precificacao.portos' | translate}}</div>
            <div class="col d-none d-sm-block"></div>
            <div class="col">
              <select class="form-control input-small" [(ngModel)]="dto.portoInicial" (change)="carregarListas()" name="portoselect">
                <option [ngValue]="null">Selecione</option>
                <option *ngFor="let porto of listaPortos" [ngValue]="porto">{{porto.nome}}</option>
              </select>

            </div>
            <div class="col"></div>
          </div>
          <div class="form-row pl-3 pt-1">
            <div class="col"><span>{{'precificacao.cbot' | translate}}</span></div>
            <div class="col d-none d-sm-block">cts/bsh</div>
            <div class="col">
              <select class="form-control" [(ngModel)]="dto.cBot" (change)="substituir(dto.cBot)" name="cBot">
                <option [ngValue]="null">Selecione</option>
                <option *ngFor="let quotes of cBoat.quotes" [ngValue]="quotes.quoteCode">{{quotes.expirationMonth}}</option>
              </select>
            </div>
            <div class="col text-center">
              {{dto.cBotNumber | number:'1.2-4'}}
            </div>
          </div>
          <div class="form-row pl-3 pt-1 pb-1">
            <div class="col"><span>{{'precificacao.premio' | translate}}</span></div>
            <div class="col d-none d-sm-block">cts/bsh</div>
            <div class="col">
              <select class="form-control" [(ngModel)]="dto.premio" [disabled]="premios.length === 0" (change)="obterQuintoDiaUtil(dto.premio.dataPremioPt,4,false); limparListaFrete();" name="premio">
                <option [ngValue]="null">Selecione</option>
                <option *ngFor="let premio of premios" [ngValue]="premio">{{premio.graoString}} - {{premio.dataPremio}}</option>
              </select>
            </div>
            <div class="col text-center">
              <input type="text"
                     [(ngModel)]="dto.premio.valor"
                     class="form-control text-center"
                     [disabled]="podeEditarPrecicificacao()"
                     name="premioValor"
                     currencyMask
                     [options]="{ prefix: '', thousands: '.', decimal: ',',precision:2 }" />
            </div>
          </div>
          <!-- #endregion -->
          <!-- #region Frete -->
          <div class="form-row bg-dark text-white p-3">
            <div class="col">{{'Menus.Frete' | translate}}</div>
            <div class="col d-none d-sm-block"></div>
            <div></div>
            <div class="col text-center">R$</div>
          </div>
          <div class="form-row pl-3 pt-1">
            <div class="col-sm-12 col-lg-3 col-12"><span>{{'precificacao.rodo' | translate}}</span></div>
            <div class="col-sm-12 col-lg-3 col-4 mt-1">
              <select class="form-control" [(ngModel)]="dto.pracaRodo" [disabled]="listaPraca.length === 0" (change)="obterFreteRodo()" name="pracaRodo">
                <option [ngValue]="null">Selecione</option>
                <option *ngFor="let praca of listaPraca" [ngValue]="praca">{{praca.nomePraca}}</option>
              </select>
            </div>
            <div class="col-sm-12 col-lg-3 col-4 mt-1">
              <select class="form-control" [(ngModel)]="dto.rodo" [disabled]="listaRodo.length === 0" (change)="obterQuintoDiaUtil(dto.rodo.dataFreteString,2,false)" name="rodo">
                <option [ngValue]="null">Selecione</option>
                <option *ngFor="let rodo of listaRodo" [ngValue]="rodo">{{rodo.dataFrete}}</option>
              </select>
            </div>
            <div class="text-center col-sm-12 col-lg-3 col-4 mt-1">
              <input type="text"
                     [(ngModel)]="dto.rodo.valor"
                     class="form-control text-center"
                     name="rodoValor"
                     currencyMask
                     [options]="{ prefix: '', thousands: '.', decimal: ',',precision:2 }" />
            </div>
          </div>
          <div class="form-row pl-3 pt-1 pb-1">
            <div class="col-sm-12 col-lg-3 col-12"><span>{{'precificacao.ferro' | translate}}</span></div>
            <div class="col-sm-12 col-lg-3 col-4 mt-1">
              <select class="form-control" [(ngModel)]="dto.pracaFerro" [disabled]="listaPraca.length === 0" (change)="obterFreteFerro()" name="pracaFerro">
                <option [ngValue]="null">Selecione</option>
                <option *ngFor="let praca of listaPraca" [ngValue]="praca">{{praca.nomePraca}}</option>
              </select>
            </div>
            <div class="col-sm-12 col-lg-3 col-4 mt-1">
              <select class="form-control" [(ngModel)]="dto.ferro" [disabled]="listaFerro.length === 0" (change)="obterQuintoDiaUtil(dto.ferro.dataFreteString,3,false)" name="ferro">
                <option [ngValue]="null">Selecione</option>
                <option *ngFor="let ferro of listaFerro" [ngValue]="ferro">{{ferro.dataFrete}}</option>
              </select>
            </div>
            <div class="text-center col-sm-12 col-lg-3 col-4 mt-1">
              <input type="text"
                     [(ngModel)]="dto.ferro.valor"
                     class="form-control text-center" name="ferroValor"
                     currencyMask
                     [options]="{ prefix: '', thousands: '.', decimal: ',',precision:2 }" />
            </div>
          </div>
          <!-- #endregion -->
          <!-- #region HEDGE -->
          <div class="form-row bg-dark text-white p-3">
            <div class="col d-none d-sm-block">{{'precificacao.hedgeCambio' | translate}}</div>
            <div class="col"> {{'data' | translate}}</div>
            <div class="col text-center">{{'precificacao.compra' | translate}}</div>
            <div class="col text-center">{{'precificacao.venda' | translate}}</div>
          </div>
          <div class="form-row pl-3 pt-1">
            <div class="col-12 col-sm-3"><span>{{'precificacao.mercado' | translate}}</span></div>
            <div class="col">
              <input class="form-control" placeholder="dd/mm/yyyy"
                     name="dataMercInput" [(ngModel)]="dataMerc" ngbDatepicker #dataMercInput="ngbDatepicker" (click)="dataMercInput.toggle()"
                     (ngModelChange)="obterDolarFuturo(dataMerc,1)" />
            </div>
            <div class="col text-center">
              <span>{{dto.cambioMerc.dolarCompra | number:'1.2-4'}}</span>
            </div>
            <div class="col text-center">
              <span>{{dto.cambioMerc.dolarVenda | number:'1.2-4' }}</span>
            </div>
          </div>
          <div class="form-row pl-3 pt-1">
            <div class="col-12 col-sm-3"><span>{{'precificacao.rodo' | translate}}</span></div>
            <div class="col">
              <input class="form-control" placeholder="dd/mm/yyyy"
                     name="dataRodoInput" [(ngModel)]="dataRodo"
                     ngbDatepicker
                     #dataRodoInput="ngbDatepicker"
                     (click)="dataRodoInput.toggle()"
                     (ngModelChange)="obterDolarFuturo(dataRodo,2)" />
            </div>
            <div class="col text-center">
              <span>{{dto.cambioRodo.dolarCompra | number:'1.2-4'}}</span>
            </div>
            <div class="col text-center">
              <span>{{dto.cambioRodo.dolarVenda | number:'1.2-4'}}</span>
            </div>
          </div>
          <div class="form-row pl-3 pt-1 pb-1">
            <div class="col-12 col-sm-3"><span>{{'precificacao.ferro' | translate}}</span></div>
            <div class="col">
              <input class="form-control" placeholder="dd/mm/yyyy"
                     name="dataFerroInput"
                     [(ngModel)]="dataFerro"
                     ngbDatepicker
                     #dataFerroInput="ngbDatepicker"
                     (click)="dataFerroInput.toggle()"
                     (ngModelChange)="obterDolarFuturo(dataFerro,3)" />
            </div>
            <div class="col text-center">
              <span> {{dto.cambioFerro.dolarCompra | number:'1.2-4'}}</span>
            </div>
            <div class="col text-center">
              <span> {{dto.cambioFerro.dolarVenda | number:'1.2-4'}}</span>
            </div>
          </div>
          <!-- #endregion -->
          <!-- #region Componentes -->
          <div class="form-row bg-dark text-white p-3">
            <div class="col col-lg-9">{{'Menus.Componente' | translate}}</div>
            <div class="col col-lg-3">
              <select class="form-control input-small" [(ngModel)]="dto.componente" (change)="obterComponenteItem()" name="componenteselect">
                <option [ngValue]="null">Selecione</option>
                <option *ngFor="let componente of listaComponente" [ngValue]="componente">{{componente.nome}}</option>
              </select>
            </div>
          </div>
          <div class="form-row bg-secondary text-white d-none d-lg-flex" *ngIf="listaItemComponente.length > 0 && ehAdministrador">
            <div class="col">{{'componente.custo' | translate}}</div>
            <div class="col text-right">{{'precificacao.compra' | translate}}</div>
            <div class="col text-right">{{'precificacao.venda' | translate}}</div>
          </div>
          <div *ngIf="ehAdministrador">
            <div class="form-row border d-none d-lg-flex" *ngFor="let componente of listaItemComponente">
              <div class="col">{{componente.nome}}</div>
              <div class="col text-right"><span>{{calculoComponenteCompra(componente)| number:'1.2-2'}} $</span></div>
              <div class="col text-right"> <span>{{calculoComponenteVenda(componente)| number:'1.2-2'}} $</span></div>
            </div>
          </div>
          <div class="form-row p-3 d-none d-lg-flex" *ngIf="listaItemComponente.length == 0 && ehAdministrador">
            <div class="col text-center">
              <span>{{'tabela.naoEcontrado' | translate}}</span>
            </div>
          </div>
          <!-- #endregion -->
          <!-- #region Total -->
          <div class="form-row bg-dark text-white p-3">
            <div class="col col-lg-9">{{'precificacao.total' | translate}}</div>
            <div class="col col-lg-3">
              <input type="text"
                     [(ngModel)]="soma"
                     name="soma"
                     class="form-control text-center"
                     currencyMask
                     [options]="{ prefix: '', thousands: '.', decimal: ',',precision:2 }" />
            </div>
          </div>
          <div class="form-row bg-secondary text-white">
            <div class="col"></div>
            <div class="col"></div>
            <div class="col text-center">{{'precificacao.compra' | translate}}</div>
            <div class="col text-center">{{'precificacao.venda' | translate}}</div>
          </div>
          <div class="form-row pl-3 pt-1">
            <div class="col">{{'precificacao.base' | translate}} </div>
            <div class="col">(usd/ton) </div>
            <div class="col text-center">  {{baseCompra() | number:'1.2-2'}} </div>
            <div class="col text-center">  {{baseVenda() | number:'1.2-2'}} </div>
          </div>
          <div class="form-row pl-3 pt-1">
            <div class="col">{{'precificacao.bid' | translate}} </div>
            <div class="col">R$/sc </div>
            <div class="col text-center">   {{bidReaisCompra() | number:'1.2-2' }} </div>
            <div class="col text-center">  {{bidReaisVenda() | number:'1.2-2' }} </div>
          </div>
          <div class="form-row pl-3 pt-1">
            <div class="col">{{'precificacao.bid' | translate}} </div>
            <div class="col">usd/sc</div>
            <div class="col text-center"> {{bidDolarCompra() | number:'1.2-2' }} </div>
            <div class="col text-center"> {{bidDolarVenda() | number:'1.2-2' }} </div>
          </div>


          <!-- #endregion -->
          <!-- #region Oferta -->
          <div class="form-row bg-dark text-white pl-3 pt-1">
            <div class="col">{{'tabela.bookOfertas.oferta' | translate}}</div>
            <div class="col text-center">{{'precificacao.compra' | translate}}</div>
            <div class="col text-center">{{'precificacao.venda' | translate}}</div>
          </div>
          <div class="form-row pl-3 pt-1">
            <div class="col">{{'precificacao.cbot' | translate}}</div>
            <div class="col text-center"> {{ofertaCompraCbot() | number:'1.2-2' }}</div>
            <div class="col text-center"> {{ofertaVendaCbot() | number:'1.2-2' }}</div>
          </div>
          <div class="form-row pl-3 pt-1">
            <div class="col">{{'precificacao.premio' | translate}}</div>
            <div class="col text-center">  {{ofertaCompraPremio() | number:'1.2-2' }}</div>
            <div class="col text-center"> {{ofertaVendaPremio() | number:'1.2-2' }}</div>
          </div>
          <div class="form-row pl-3 pt-1">
            <div class="col">R$/sc</div>
            <div class="col text-center">
              <input type="text" name="ofertaCompraRs" class="form-control" [(ngModel)]="dto.ofertaCompraRs" (keyup)="ofertaCompraReaisParaDolar()" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',',precision:2 }">
            </div>
            <div class="col text-center">
              <input type="text" name="ofertaVendaRs" class="form-control" [(ngModel)]="dto.ofertaVendaRs" (keyup)="ofertaVendaReaisParaDolar()" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',',precision:2 }">
            </div>
          </div>
          <div class="form-row pl-3 pt-1 pb-1">
            <div class="col">usd/sc</div>
            <div class="col text-center">
              <input type="text" name="ofertaCompra" class="form-control" [(ngModel)]="dto.ofertaCompra" (keyup)="ofertaCompraDolarParaReais()" currencyMask [options]="{ prefix: '$ ', thousands: '.', decimal: ',',precision:2 }">
            </div>
            <div class="col text-center">
              <input type="text" name="ofertaVenda" class="form-control" [(ngModel)]="dto.ofertaVenda" (keyup)="ofertaVendaDolarParaReais()" currencyMask [options]="{ prefix: '$ ', thousands: '.', decimal: ',',precision:2 }">
            </div>
          </div>
          <!-- #endregion -->
          <!-- #region Salvar Como -->

          <div class="form-row p-3 bg-dark text-white">
            <div class="col">
              {{'precificacao.salvarComo' | translate}}
            </div>
          </div>
          <div class="form-row p-3">
            <div class="col-sm-1 col-2">
              {{'precificacao.nome' | translate}}
            </div>
            <div class="col-sm-5 col-10">
              <input type="text" name="nomemprec" class="form-control" [(ngModel)]="dto.nome" />
            </div>
            <div class="col-sm-5 col-12 pt-2">
              <div class="form-group">
                <label>Moeda:</label>
                <div class="form-check-inline">
                  <label class="form-check-label">
                    <input type="radio" class="form-check-input" value="1" name="moeda" [(ngModel)]="dto.idMoeda">Dólar US$
                  </label>
                </div>
                <div class="form-check-inline">
                  <label class="form-check-label">
                    <input type="radio" class="form-check-input" value="2" name="moeda" [(ngModel)]="dto.idMoeda">Real R$
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row p-3">
            <div class="col">
              <button class="btn btn-success btn-block" (click)="cadastrar(1)">{{'precificacao.compra' | translate}}</button>
            </div>
            <div class="col">
              <button class="btn btn-success btn-block" (click)="cadastrar(2)">{{'precificacao.venda' | translate}}</button>
            </div>
          </div>
          <!-- #endregion -->
        </form>
      </div>
    </nb-card-body>
  </nb-card>
</main>
