<main>
  <nb-card [nbSpinner]="loading">
    <nb-card-header>
      <h3>{{'Menus.Portos' | translate}}</h3>
    </nb-card-header>
    <nb-card-body>
      <div class="d-flex">
        <button class="btn btn-success" (click)="open(novoPorto);">{{'btn.novo' | translate}} <i class="mdi mdi-anchor"></i></button>
      </div>
      <div class="table-gp">
        <table class="table">
          <thead class="thead-dark">
            <tr>
              <th>{{'tabela.nome' | translate}}</th>
              <th>{{'tabela.sigla' | translate}}</th>
              <th>{{'tabela.acao' | translate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let porto of listaPorto">
              <td>{{porto.nome}}</td>
              <td>{{porto.sigla}}</td>
              <td>
                <div class="d-flex">
                  <button class="btn btn-outline-danger mr-2" (click)="open(mymodal);selecionarPorto(porto.portoId,false)">
                    <i class="mdi mdi-delete"></i>
                  </button>

                  <button class="btn btn-outline-warning" (click)="open(edicaoPorto);selecionarPorto(porto.portoId,true)">
                    <i class="mdi mdi-table-edit"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="d-flex justify-content-end">
        <ngb-pagination [collectionSize]="filtro.total"
                        [pageSize]="filtro.tamanhoPagina"
                        [(page)]="filtro.numeroPagina"
                        [maxSize]="10"
                        [rotate]="true"
                        [boundaryLinks]="true"
                        (pageChange)="obterListaPorto($event)"></ngb-pagination>
      </div>

    </nb-card-body>
  </nb-card>
</main>


<ng-template #mymodal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">{{'porto.inativarPorto'|translate}}</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      {{'porto.inativarPorto'|translate}}?
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Cross click')" data-dismiss="modal">{{'fechar'|translate}}</button>
    <button type="button" class="btn btn-danger" (click)="inativarPorto()">{{'sim'|translate}}</button>
  </div>
</ng-template>


<ng-template #novoPorto let-modal>
  <div class="modal-header">
    <h5 class="modal-title">{{'porto.novoPorto'|translate}}</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class="form-group col-12 pl-0">
      <label>{{'estado'|translate}}</label>
      <ng-multiselect-dropdown [placeholder]="'Selecione os Estados'"
                               [data]="listaEstados"
                               [(ngModel)]="novoPorto.estados"
                               [ngModelOptions]="{standalone: true}"
                               [settings]="dropdownSettings">
      </ng-multiselect-dropdown>
    </div>
    <div class="form-group col-12 pl-0">
      <label>{{'tabela.nome'|translate}}</label>
      <input type="text" class="form-control" [(ngModel)]="novoPorto.nome" name="nome">
    </div>
    <div class="form-group col-12 pl-0">
      <label>{{'tabela.sigla'|translate}}</label>
      <input type="text" class="form-control" [(ngModel)]="novoPorto.sigla" name="sigla">
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Cross click')" data-dismiss="modal">{{'btn.fechar'|translate}}</button>
    <button type="button" class="btn btn-success" (click)="cadastrarPorto(novoPorto.nome,novoPorto.sigla,novoPorto.estados)">{{'btn.cadastrar'|translate}}</button>
  </div>
</ng-template>

<ng-template #edicaoPorto let-modal>
  <div class="modal-header">
    <h5 class="modal-title">{{'porto.edicaoPorto'|translate}}</h5>

    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
      <div class="form-group col-12 pl-0">
        <label>{{'estado'|translate}}</label>
        <ng-multiselect-dropdown [placeholder]="'Selecione os Estados'"
                                 [data]="listaEstados"
                                 [(ngModel)]="edPorto.estados"
                                 [ngModelOptions]="{standalone: true}"
                                 [settings]="dropdownSettings">
        </ng-multiselect-dropdown>
      </div>
      <div class="form-group col-12 pl-0">
        <label>{{'tabela.nome'|translate}}</label>
        <input type="text" class="form-control" [(ngModel)]="edPorto.nome" name="nomeed">
      </div>
      <div class="form-group col-12 pl-0">
        <label>{{'tabela.sigla'|translate}}</label>
        <input type="text" class="form-control" [(ngModel)]="edPorto.sigla" name="siglaed">
      </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Cross click')" data-dismiss="modal">{{'fechar'|translate}}</button>
    <button type="button" class="btn btn-success" (click)="editarPorto(edPorto)">{{'btn.cadastrar'|translate}}</button>
  </div>
</ng-template>
